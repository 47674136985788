const BOM = '\ufeff';
const TYPE = 'text/csv';

class CSV {
    download( content = '', name = 'test.csv' ) {
        let blob = new Blob( [ BOM + content ], { type: TYPE } );

        let link = document.createElement( 'a' );
        link.href = window.URL.createObjectURL( blob );
        link.download = name + '.csv';
        link.click();
    }
}

export { BOM, TYPE };
export default new CSV();
