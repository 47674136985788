<template>
    <div>
        <errors-bag-list class='d-print-none'></errors-bag-list>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2 d-print-none">
                <b-row>
                    <b-col md='3'>
                        <date-time-picker
                            locale='ru'
                            input-size='sm'
                            position='bottom'
                            label='Выберите дату'
                            id='timesheet_datepicker'

                            :no-label='true'
                            :no-header='true'
                            :only-date='true'
                            :no-button='true'
                            :auto-close='true'
                            :no-clear-button='true'

                            format='YYYY-MM-DD'
                            formatted='YYYY-MM-DD'

                            v-model='filters.date'
                        ></date-time-picker>
                    </b-col>
                    <b-col md='2'>
                        <shift-input v-model='filters.shift'></shift-input>
                    </b-col>
                    <b-col md='2'>
                        <department-picker v-model='filters.department' :salaryEnabled='true' :nullable='true'/>
                    </b-col>
                    <b-col md='5'>
                        <div class='pull-right'>
                            <b-button variant='outline-dark' @click='resetFilters()' v-if='filtersExists'>
                                Сбросить фильтр
                            </b-button>
                            <b-button variant='danger' @click='openTemplates()'>Шаблоны табеля</b-button>
                            <b-button variant='success' @click='printTimesheet'>Печать табеля</b-button>
                            <b-button variant='secondary' @click='showTimesheetExportModal()' v-if="$auth.check([ROLE_ACCOUNTANT])">Экспорт в CSV</b-button>
                            <b-button variant='primary' @click='applyFilters()'>Обновить</b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card-header>

            <h2 class='d-none d-print-block' style='margin:20px 0 30px 0;'>
                <b>Табель рабочего времени на {{ moment( filters.date ).format( 'DD.MM.YYYY' ) }}</b>
            </h2>

            <b-table
                stipred
                :responsive="true"
                :fields="fields"
                head-variant="dark"
                :items="preparedItems"
                :no-local-sorting="true"
            >
                <template v-for='field in fields' :slot='field.key' slot-scope='row' v-if='field.key !== "actions"'>
                    <span :class="{ 'bold-cell': row.item.bold }">{{ row.value }}</span>
                </template>

                <template #cell(actions)="data" v-if='editIsAllowed'>
                    <template v-if='data.item.actions'>
                        <b-button variant="outline-primary" :to="routerTimesheetRecord( data.item )">
                            <i class="fas fa-pen"></i>
                        </b-button>

                        <b-button variant="outline-danger" @click="deleteRecord( data.item.id )">
                            <i class="fas fa-times"></i>
                        </b-button>
                    </template>
                </template>
            </b-table>

            <b-card-footer footer-tag='footer' class='p-2 d-print-none' v-if='editIsAllowed'>
                <timesheet-create :parameters='filters' @update-timesheet='applyFilters()'></timesheet-create>
            </b-card-footer>
        </b-card>

        <timesheet-templates ref='TimesheetTemplates' :parameters='filters' @update-timesheet='applyFilters()' />
    </div>
</template>

<script>
    import moment from 'moment';

    import DateTimePicker from 'vue-ctk-date-time-picker';

    import ShiftInput from '@components/_common/ShiftInput';
    import DepartmentPicker from '@components/_common/DepartmentPicker';
    import ErrorsBagList from '@components/_common/ErrorsBagList';

    import TimesheetCreate from './TimesheetCreate';
    import TimesheetExport from './TimesheetExport';
    import TimesheetTemplates from './TimesheetTemplates';

    import ErrorsBag from '@utils/errorsBag';
    import lsfMixin from '@utils/localStorageFilterMixin';
    import { SALARY_ENDPOINT } from '@utils/endpoints';
    import { ROLE_ACCOUNTANT } from '@utils/Roles';

    const DATE_FORMAT = 'YYYY-MM-DD';

    const FILTERS_PATTERN = {
        shift: null,
        department: null,
        date: moment().format( DATE_FORMAT )
    };

    const EXPORT_MODAL_PARAMS = {
        width: 450,
        height: 'auto',
        adaptive: true,
        scrollable: true,
        classes: 'overflowable'
    }

    export default {
        name: "TimesheetDashboard",
        mixins: [ lsfMixin ],
        components: {
            DateTimePicker,
            ShiftInput,
            DepartmentPicker,
            ErrorsBagList,
            TimesheetCreate,
            TimesheetTemplates
        },
        computed: {
            editIsAllowed() {
                if (this.$auth.check([ROLE_ACCOUNTANT])) {
                    return true;
                }

                let minAllowedDate = moment().subtract( 4, 'days' );
                return moment( this.filters.date ).isSameOrAfter( minAllowedDate );
            },
            preparedItems() {
                let preparedItems = [];

                let summary = {
                    index: 'Итого: ',
                    user: null,
                    salary: 0,
                    bold: true,
                    shift: null,
                    department: null,
                    day_type: null,
                    actions: false,
                    hours_worked: 0,
                    started_at: null,
                    work_position: null
                };

                this.items.forEach( ( item, index ) => {
                    let contractor = ''
                    if (item.user
                        && item.user.contractor
                        && item.user.contractor.name
                    ) {
                        contractor = item.user.contractor.name
                    }
                    preparedItems.push( {
                        index: ( index + 1 ),
                        id: item.id,
                        bold: false,
                        actions: true,
                        salary: item.salary,
                        department: item.department.name,
                        started_at: item.started_at,
                        day_type: item.dayType.name,
                        hours_worked: item.hours_worked,
                        shift: item.actualShift.shift.name,
                        work_position: item.workPosition.name,
                        user: item.user ? item.user.fullname : '!!! ПОЛЬЗОВАТЕЛЬ НЕ НАЙДЕН !!!',
                        contractor: contractor
                    } );

                    summary.salary += item.salary;
                    summary.hours_worked += item.hours_worked;
                } );

                if ( preparedItems.length > 0 ) {
                    preparedItems.push( summary );
                }

                return preparedItems;
            }
        },
        watch: {
            filters: {
                deep: true,
                handler( value ) {
                    this.watchFilters( value );
                }
            },
        },
        data() {
            return {
                moment,
                ErrorsBag,
                ROLE_ACCOUNTANT,

                filters: {
                    date: null,
                    shift: null,
                    department: null
                },

                items: [],
                fields: [
                    { key: 'index', label: '#' },
                    { key: 'user', label: 'Пользователь' },
                    { key: 'contractor', label: 'Подрядчик' },
                    { key: 'work_position', label: 'Должность' },
                    { key: 'shift', label: 'Смена' },
                    { key: 'department', label: 'Подразделение' },
                    { key: 'started_at', label: 'Время начала' },
                    { key: 'hours_worked', label: 'Отработано часов' },
                    { key: 'salary', label: 'ЗП за смену, руб' },
                    { key: 'actions', label: 'Действия', class: 'd-print-none' }
                ]
            };
        },
        methods: {
            goTo( record ) {
                this.$router.push( this.routerTimesheetRecord( record ) );
            },
            routerTimesheetRecord( record ) {
                return { name: 'TimesheetEdit', params: { id: record.id } };
            },
            printTimesheet() {
                window.print();
            },
            openTemplates() {
                this.$refs.TimesheetTemplates.openModal();
            },

            deleteRecord( id ) {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить эту запись?' ) ) {
                    this.$http
                        .delete( SALARY_ENDPOINT + '/timesheet/' + id )
                        .then( () => {
                            this.$toast.success( 'Запись успешно удалена' );
                            this.applyFilters();
                        } )
                        .catch( response => {
                            ErrorsBag.fill( response.body );
                            this.$toast.error( 'Есть ошибки! Ознакомьтесь со списком вверху страницы' );
                            window.scrollTo( { top: 0, behavior: 'smooth' } );
                        } );
                }
            },

            applyFilters() {
                this.fetchTimesheet();
            },

            fetchTimesheet() {
                ErrorsBag.discard();

                this.items = [];
                let filters = this.getFilters;

                let params = {
                    filters: {
                        date: moment( filters.date ).format( DATE_FORMAT ),
                        shift: { id: filters.shift ? filters.shift.id : null },
                        department: { id: filters.department ? filters.department.id : null }
                    },
                    limit: 1000,
                    appends: [ 'salary' ],
                    with: [ 'user', 'workPosition', 'actualShift.shift', 'department', 'dayType' ]
                };

                this.$http
                    .get( SALARY_ENDPOINT + '/timesheet', { params } )
                    .then( response =>
                        this.items = response.data.data.data.sort(
                        ( a, b ) => ( a.user ? (a.user.fullname ? a.user.fullname : a.user.username) : '' )
                            .localeCompare( b.user ? (b.user.fullname ? b.user.fullname : b.user.username) : '' )
                    ) )
                    .catch( response => {
                        ErrorsBag.fill( response.body );
                        this.$toast.error( 'Есть ошибки! Ознакомьтесь со списком вверху страницы' );
                        window.scrollTo( { top: 0, behavior: 'smooth' } );
                    } );
            },

            showTimesheetExportModal() {
                this.$modal.show(TimesheetExport, {}, EXPORT_MODAL_PARAMS);
            }
        },
        mounted() {
            this.initFilters( this, FILTERS_PATTERN );
        }
    }
</script>

<style lang='sass' scoped>
    .bold-cell
        font-weight: bold
</style>

<style scoped>
    @media print {
        .d-none.d-print-block b {
            color: black;
            font-weight: bold;
        }
    }
</style>
