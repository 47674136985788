<template>
    <b-modal
        size="lg"
        :title="title"
        ref="TimesheetTemplatesModal"
        id="timesheet-templates-modal"
        ok-title="Отмена"
        ok-variant="secondary"
        ok-only
        @ok="hideModal"
    >
        <errors-bag-list/>

        <template v-if="valid">
            <b-row class="mb-2">
                <b-col cols="12">
                    <b-input-group class="mt-3">
                        <b-form-input
                            v-model="template.name"
                            autocomplete="tpl_name"
                            placeholder="Введите наименование шаблона"
                        />

                        <b-input-group-append>
                            <b-button variant="outline-success" @click="createTemplate">Создать новый шаблон</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-table
                        striped
                        :responsive="true"
                        :fields="fields"
                        :items="templates"
                        head-variant="dark"
                        :no-local-sorting="true"
                    >
                        <template #cell(actual_shift)="data">
                            {{ data.item.actual_shift.name }}
                        </template>

                        <template #cell(created_at)="data">
                            {{ moment(data.item.created_at).format('YYYY-MM-DD hh:mm:ss') }}
                        </template>

                        <template #cell(actions)="data">
                            <b-button-group>
                                <b-button variant="outline-success" size="sm" @click="applyTemplate( data.item.id )">
                                    <b-icon-check2-square/>
                                </b-button>
                                <b-button variant="outline-warning" size="sm" @click="updateTemplate( data.item.id )">
                                    <i class='fas fa-save'/>
                                </b-button>
                                <b-button variant="outline-danger" size="sm" @click="removeTemplate( data.item.id )">
                                    <b-icon-trash-fill/>
                                </b-button>
                            </b-button-group>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </template>
        <template v-else>
            <div class="text-center">
                <h4 class="text-danger">
                    Для работы с шаблонами необходимо выбрать: <b>дату</b>, <b>смену</b> и <b>подразделение</b>!
                </h4>
            </div>
        </template>
    </b-modal>
</template>

<script>
import moment    from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import ErrorsBag     from '../../../utils/errorsBag';
import ErrorsBagList from '../../_common/ErrorsBagList';

import {SALARY_ENDPOINT} from '../../../utils/endpoints';

export default {
    name: 'TimesheetTemplates',
    props: {
        parameters: {type: Object, default: () => null}
    },
    components: {ErrorsBagList},
    computed: {
        title() {
            return 'Шаблоны табеля: ' + (this.department ? this.department.name : '');
        },
        valid() {
            return this.date && this.shift && this.department;
        }
    },
    data() {
        return {
            moment,

            templates: [],
            template: {name: null},
            fields: [
                {key: 'name', label: 'Наименование'},
                {key: 'actual_shift', label: 'Создан по смене'},
                {key: 'created_at', label: 'Дата создания'},
                {key: 'actions', label: 'Действия'},
            ],

            date: null,
            shift: null,
            department: null,
        };
    },
    methods: {
        openModal() {
            this.$refs.TimesheetTemplatesModal.show();

            this.applyParameters();

            if (this.valid) {
                this.fetchTemplates();
            }
        },
        hideModal() {
            this.$refs.TimesheetTemplatesModal.hide();

            this.clearParameters();

            ErrorsBag.discard();
        },

        fetchTemplates() {
            ErrorsBag.discard();

            this.templates = [];

            let params = {
                with: ['items', 'actualShift'],
                filters: {department: this.department}
            };

            this.$http
                .get(SALARY_ENDPOINT + '/timesheet/templates', {params})
                .then(response => this.templates = response.data.data.data)
                .catch(response => {
                    ErrorsBag.fill(response.body);
                });

        },
        createTemplate() {
            ErrorsBag.discard();

            let params = {
                name: this.template.name,
                shift: {id: this.shift.id},
                department: {id: this.department.id},
                date: moment(this.date).format('YYYY-MM-DD'),
            };

            this.$http
                .post(SALARY_ENDPOINT + '/timesheet/templates', params)
                .then(() => {
                    this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                    this.fetchTemplates();
                    this.template.name = null;
                })
                .catch(response => {
                    ErrorsBag.fill(response.body);
                });
        },
        applyTemplate(id) {
            this.$bvModal.msgBoxConfirm(
                'Вы уверены, что хотите применить этот шаблон?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    centered: true
                }
            ).then((value) => {
                if (value) {
                    ErrorsBag.discard();

                    let params = {
                        shift: {id: this.shift.id},
                        date: moment(this.date).format('YYYY-MM-DD'),
                    };

                    this.$http
                        .post(SALARY_ENDPOINT + '/timesheet/templates/' + id + '/apply', params)
                        .then(() => {
                            this.$emit('update-timesheet');
                            this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                            this.hideModal();
                        })
                        .catch(response => {
                            ErrorsBag.fill(response.body);
                        });
                }
            })
        },
        removeTemplate(id) {
            this.$bvModal.msgBoxConfirm(
                'Вы уверен, что хотите удалить этот шаблон?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    centered: true
                }
            ).then((value) => {
                if (value) {
                    ErrorsBag.discard();

                    this.$http
                        .delete(SALARY_ENDPOINT + '/timesheet/templates/' + id)
                        .then(() => {
                            this.$bvToast.toast('Успешно удалено', {variant: 'success'});
                            this.fetchTemplates();
                        })
                        .catch(response => {
                            ErrorsBag.fill(response.body);
                        })
                }
            })
        },
        updateTemplate(id) {
            this.$bvModal.msgBoxConfirm(
                'Вы уверены, что хотите заменить этот шаблон?',
                {
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    centered: true
                }
            ).then((value) => {
                if (value) {
                    ErrorsBag.discard();

                    let params = {
                        shift: {id: this.shift.id},
                        department: {id: this.department.id},
                        date: moment(this.date).format('YYYY-MM-DD'),
                    };

                    this.$http
                        .put(SALARY_ENDPOINT + '/timesheet/templates/' + id, params)
                        .then(() => {
                            this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                            this.fetchTemplates();
                        })
                        .catch(response => {
                            ErrorsBag.fill(response.body)
                        });
                }
            });
        },

        applyParameters() {
            if (JSON.stringify(this.parameters.date) !== JSON.stringify(this.date)) {
                this.date = cloneDeep(this.parameters.date);
            }

            if (JSON.stringify(this.parameters.shift) !== JSON.stringify(this.shift)) {
                this.shift = cloneDeep(this.parameters.shift);
            }

            if (JSON.stringify(this.parameters.department) !== JSON.stringify(this.department)) {
                this.department = cloneDeep(this.parameters.department);
            }
        },
        clearParameters() {
            this.date = null;
            this.shift = null;
            this.department = null;
            this.template = {name: null};
        }
    }
}
</script>
