<template>
    <b-alert v-if="ErrorsBag.state()" variant="danger" show>
        <template v-for="(error, index) of ErrorsBag.list()">
            {{ index + 1 }}. {{ error }} <br>
        </template>
    </b-alert>
</template>

<script>
import ErrorsBag from '@utils/errorsBag';

export default {
    name: 'ErrorsBag',
    data() {
        return {ErrorsBag};
    }
}
</script>
