<template>
    <div class='all-wrapper'>
        <div class='select-wrapper' :style="{ width: clearButton && currentValue !== null ? '90%' : '100%' }">
            <multi-select
                v-if="users"
                track-by='id'
                select-label=''
                label='fullname'
                :options="users"
                @input="onInput"
                :multiple="false"
                selected-label=""
                deselect-label=""
                :searchable="true"
                :show-labels="true"
                v-model="currentValue"
                :close-on-select="true"
                :show-no-results="false"
                placeholder="Пользователи"
                @search-change="fetchUsers"
                :custom-label='getUsername'
                :disabled="disabled"
            ></multi-select>
        </div>
        <div class='button-wrapper' v-if='clearButton && currentValue !== null'>
            <i class='fas fa-times pointer-behavior' @click='clear()'></i>
        </div>
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import debounce from 'lodash/debounce';
    import cloneDeep from 'lodash/cloneDeep';
    import MultiSelect from 'vue-multiselect';

    import { USERS_ENDPOINT } from '../../utils/endpoints';

    export default {
        name: "UserInput",
        components: { MultiSelect },
        props: {
            value: { type: Object, default: () => null },
            clearButton: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        watch: {
            value() {
                this.setValue();
            }
        },
        data() {
            return {
                users: [],
                currentValue: cloneDeep( this.value )
            }
        },
        methods: {
            clear() {
                this.currentValue = null;
                this.onInput();
            },
            onInput() {
                this.$emit( 'input', this.currentValue );
            },

            getUsername( user ) {
                let username = user.username;

                if ( user.fullname ) {
                    username += ' (' + user.fullname + ')';
                }

                return username;
            },

            setValue() {
                this.currentValue = cloneDeep( this.value );
            },
            fetchUsers: debounce( function ( query ) {
                this.users = [];

                if ( !query ) {
                    return null;
                }

                let params = {
                    limit: 10,
                    without_loading: true,
                    filters: { search: query, all: true }
                };

                this.$http
                    .get( USERS_ENDPOINT, { params } )
                    .then( response => this.users = response.data.data.data );
            }, 750 )
        }
    }
</script>

<style lang='sass' scoped>
    .all-wrapper
        width: 100%
        display: block

    .select-wrapper
        width: 90%
        display: inline-block

    .button-wrapper
        width: 5%
        text-align: right
        display: inline-block

    .pointer-behavior
        cursor: pointer
</style>
