<template>
    <b-row class='text-left' align-v='end'>
        <b-col md='4'>
            <label for='user'>Пользователь</label>
            <user-input id='user' v-model='record.user'></user-input>
        </b-col>
        <b-col md='2'>
            <label for='started_at'>
                Время начала&nbsp;<i class='fas fa-clock' style='cursor:pointer;' @click='setShiftTime()'></i>
            </label>

            <date-time-picker
                input-size='sm'
                label='Выберите время'
                id='started_at'

                :disabled-hours='disabledHours'

                no-label
                only-time
                no-button-now
                no-clear-button

                format='HH:mm'
                formatted='HH:mm'

                v-model='record.started_at'
                :disabled='!parameters.shift || !typeIsWorkday'
            />
        </b-col>
        <b-col md='2'>
            <label for='hours_worked'>Отработанные часы</label>
            <b-form-input
                min='0'
                id='hours_worked'
                :disabled='!typeIsWorkday'
                v-model='record.hours_worked'
                :formatter="hoursWorkedFormatter"
            ></b-form-input>
        </b-col>
        <b-col md='4'>
            <div class='pull-right'>
                <b-button variant='success' @click='createRecord'>Добавить</b-button>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import moment from 'moment';

    import map from 'lodash/map';
    import range from 'lodash/range';
    import padStart from 'lodash/padStart';

    import DateTimePicker from 'vue-ctk-date-time-picker';

    import UserInput from '@components/_common/UserInput';

    import ErrorsBag from '@utils/errorsBag';
    import { SALARY_ENDPOINT } from '@utils/endpoints';

    const WORKDAY_TYPE = 'workday';
    const DATE_FORMAT = 'YYYY-MM-DD';
    const DEFAULT_HOURS_WORKED_IN_SHIFT = 12;

    export default {
        name: "TimesheetCreate",
        props: {
            parameters: { type: Object, default: () => null },
        },
        components: { DateTimePicker, UserInput },
        computed: {
            typeIsWorkday() {
                return this.record.dayType === null || this.record.dayType.key === WORKDAY_TYPE;
            },
            disabledHours() {
                if ( !this.parameters.shift ) {
                    return [];
                }

                let toTime = moment( this.parameters.shift.to_time );
                let fromTime = moment( this.parameters.shift.from_time );

                let hours = [];
                if ( fromTime.unix() > toTime.unix() ) {
                    hours = range( toTime.hour() + 1, fromTime.hour() );
                } else {
                    hours = range( 0, fromTime.hour() ).concat( range( toTime.hour() + 1, 24 ) );
                }

                return map( hours, hour => padStart( hour, 2, '0' ) );
            },
        },
        watch: {
            'parameters.shift': {
                deep: true,
                handler() {
                    this.record.started_at = null;
                }
            },
            parameters: {
                deep: true,
                handler() {
                    this.dataCorrection();
                }
            },
            'record.dayType': {
                deep: true,
                handler() {
                    this.record.started_at = null;
                    this.record.hours_worked = DEFAULT_HOURS_WORKED_IN_SHIFT;

                    this.dataCorrection();
                }
            }
        },
        data() {
            return {
                moment,
                ErrorsBag,

                record: {
                    user: null,
                    dayType: null,
                    started_at: null,
                    hours_worked: DEFAULT_HOURS_WORKED_IN_SHIFT
                }
            }
        },
        methods: {
            setShiftTime() {
                ErrorsBag.discard();

                if ( !this.parameters.shift ) {
                    ErrorsBag.add( 'Выберите смену' );
                    this.$toast.error( 'Есть ошибки! Ознакомьтесь со списком вверху страницы' );
                    window.scrollTo( { top: 0, behavior: 'smooth' } );
                    this.record.started_at = null;

                    return null;
                }

                this.record.started_at = moment( this.parameters.shift.fromTime ).format( 'HH:mm' );
            },
            dataCorrection() {
                if ( this.record.dayType !== null && this.record.dayType.key !== WORKDAY_TYPE ) {
                    this.setShiftTime();
                    this.record.hours_worked = DEFAULT_HOURS_WORKED_IN_SHIFT;
                }
            },

            clearRecord() {
                this.record.user = null;
                this.record.dayType = null;
                this.record.started_at = null;
                this.record.hours_worked = DEFAULT_HOURS_WORKED_IN_SHIFT;
            },
            createRecord() {
                ErrorsBag.discard();

                this.dataCorrection();

                let params = {
                    user: this.record.user,
                    started_at: this.record.started_at,
                    hours_worked: Number.parseFloat( this.record.hours_worked ),
                    date: moment( this.parameters.date ).format( DATE_FORMAT ),
                    day_type: { id: this.record.dayType ? this.record.dayType.id: null },
                    shift: { id: this.parameters.shift ? this.parameters.shift.id : null },
                    department: { id: this.parameters.department ? this.parameters.department.id: null }
                };

                this.$http
                    .post( SALARY_ENDPOINT + '/timesheet', params )
                    .then(() => {
                        this.$emit( 'update-timesheet', null );
                        this.clearRecord();
                    })
                    .catch(response => {
                        ErrorsBag.fill( response.body );
                        this.$toast.error( 'Есть ошибки! Ознакомьтесь со списком вверху страницы' );
                        window.scrollTo( { top: 0, behavior: 'smooth' } );
                    });
            },
            hoursWorkedFormatter(value) {
                return value
                    .toString()
                    .replace(",", ".")
                    .replace(/[^\d.]/g, "");
            }
        }
    }
</script>
