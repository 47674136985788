<template>
    <b-card id="TimesheetExport" class="mb-0" no-body>
        <b-card-header>Экспорт табеля учета рабочего времени</b-card-header>
        <b-card-body>
            <div class="container">
                <div class="row m-4">
                    <div class="col">
                        <b-form-group description="Дата с">
                        <date-time-picker
                            label="С"
                            locale="ru"
                            id="startDate"
                            :format="null"
                            input-size="sm"
                            position="bottom"
                            :no-label="true"
                            :no-header="true"
                            :no-button="true"
                            :no-clear-button="true"
                            :auto-close="true"
                            :only-date="true"
                            v-model="startDate"
                            formatted="DD.MM.YYYY"
                            :max-date="endDate ? moment(endDate).format('YYYY-MM-DD') : null"
                        />
                        </b-form-group>
                    </div>
                    <div class="col">
                        <b-form-group description="Дата до">
                        <date-time-picker
                            label="По"
                            locale="ru"
                            id="'date-to"
                            :format="null"
                            input-size="sm"
                            position="bottom"
                            :no-label="true"
                            :no-header="true"
                            :no-button="true"
                            :no-clear-button="true"
                            :auto-close="true"
                            :only-date="true"
                            v-model="endDate"
                            formatted="DD.MM.YYYY"
                            :min-date="startDate ? moment(startDate).format('YYYY-MM-DD') : null"
                        />
                        </b-form-group>
                    </div>
                </div>
            </div>
        </b-card-body>
        <b-card-footer>
            <div class="container">
                <div class="row">
                    <div class="col text-center">
                        <b-button variant="secondary" size="lg" @click="$emit('close')" class="m-2">Закрыть</b-button>
                        <b-button variant="success" size="lg" @click="download()" class="m-2">Скачать</b-button>
                    </div>
                </div>
            </div>
        </b-card-footer>
    </b-card>
</template>

<script>
    import moment from "moment"
    const asyncDatePicker = import("vue-ctk-date-time-picker")
    import { SALARY_ENDPOINT } from "../../../utils/endpoints"
    import CSV from "../../../utils/csv"

    export default {
        name: "TimesheetExport",
        components: {
            "date-time-picker": () => asyncDatePicker
        },
        data() {
            return {
                moment,
                startDate: moment().subtract(6, "days"),
                endDate: moment()
            }
        },
        methods: {
            download() {
                this.$http
                    .get(SALARY_ENDPOINT + "/timesheet/download", {
                        params: {
                            startDate: moment(this.startDate).format("YYYY-MM-DD"),
                            endDate: moment(this.endDate).format("YYYY-MM-DD")
                        }
                    })
                    .then(response => {
                        CSV.download(response.data, "Табель учета рабочего времени")
                    })
                    .catch(response => {
                        ErrorsBag.fill(response.body)
                        this.$toast.error('Есть ошибки! Ознакомьтесь со списком вверху страницы')
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                    })
            }
        }
    }
</script>

<style scoped>
    .card-body {
        padding: 0 !important;
    }
</style>

<style>
    #TimesheetExport .datepicker {
        z-index: 10000 !important;
    }
</style>
