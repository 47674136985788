<template>
    <select class="form-control" v-model="internalValue">
        <option :value="null">Выберите подразделение</option>
        <option v-for="option in options" :key="option.id" :value="option">{{ option.name }}</option>
    </select>
</template>

<script>
import {DEPARTMENT_ENDPOINT} from '@utils/endpoints';

export default {
    name: 'DepartmentPicker',
    props: {
        value: {
            type: Object,
        },
        allowedRoles: {
            required: false,
            default: true
        },
        salaryEnabled: {
            type: Boolean,
            required: false,
            default: null,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        placeholder: {
            type: String,
            default: 'Подразделение'
        },
        nullable: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        internalValue: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            }
        },
    },
    data() {
        return {
            options: [],
        }
    },
    methods: {
        getParams() {
            let params = {
                limit: 99,
                filters: {},
                sort: 'name',
                sortDirection: 'asc'
            };

            params.filters.allowed_roles = this.allowedRoles || [];

            if (this.salaryEnabled !== null) {
                params.filters.salary_enabled = this.salaryEnabled;
            }

            return params;
        },
        loadDepartments() {
            this.$http.get(DEPARTMENT_ENDPOINT, {
                params: this.getParams()
            }).then((response) => {
                this.options = response.data.data.data;
            }).catch((response) => this.$root.responseError(response, 'Ошибка загрузки подразделений'));
        },
    },
    mounted() {
        this.loadDepartments();
    }
}
</script>
